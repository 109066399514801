import React, { useEffect, useState } from 'react';

import { SITE_USER_COOKIE } from '@/constants/site';
import { SUBSCRIPTION_TYPE } from '@/constants/subscription-type';
import { getFromLocalStorage } from '@/helpers/cookies';

export const HbAdType = {
    HALF_PAGE_1: 175341,
    MOBILE_LEADERBOARD: 35326,
    FOOTER_MOBILE_LEADERBOARD: 37263,
    FOOTER_MOBILE_LEADERBOARD_2: 81990,
    AF_300x250_2: 37288,
    AF_300x100_MOBILE: 176437,
    STICKY_BOTTOM: 176925,
    BILLBOARD: 177071,
    AF_INTERSCROLLER: 177386,
    MAGIC_RIGHT: 177436,
    MOBILE_DESKTOP_LEADERBOARD: 177437,
    MOBILE_DESKTOP_LEADERBOARD_2: 177438,
};

const adComponents = {
    [HbAdType.MOBILE_DESKTOP_LEADERBOARD]: (
        <div class="hb-ad-static hb-ad-leaderbord d-flex-center">
            <div class="hb-ad-inner">
                <div class="hbagency_cls_static" id="hbagency_space_177437"></div>
            </div>
        </div>
    ),
    [HbAdType.MOBILE_DESKTOP_LEADERBOARD_2]: (
        <div class="hb-ad-static hb-ad-leaderbord d-flex-center">
            <div class="hb-ad-inner">
                <div class="hbagency_cls_static" id="hbagency_space_177438"></div>
            </div>
        </div>
    ),
    [HbAdType.HALF_PAGE_1]: (
        <div className="hb-ad-static hb-ad-half hb-container d-flex-center">
            <div className="hb-ad-inner">
                <div className="hbagency_cls_static" id="hbagency_space_175341"></div>
            </div>
        </div>
    ),
    [HbAdType.MOBILE_LEADERBOARD]: (
        <div className="hb-ad-static hb-ad-a320x100 hb-ad-leaderboard d-flex-center">
            <div className="hb-ad-inner">
                <div className="hbagency_cls_static" id="hbagency_space_35326"></div>
            </div>
        </div>
    ),
    [HbAdType.FOOTER_MOBILE_LEADERBOARD]: (
        <div className="hb-ad-static hb-ad-a320x100 d-flex-center">
            <div className="hb-ad-inner">
                <div className="hbagency_cls_static" id="hbagency_space_37263"></div>
            </div>
        </div>
    ),
    [HbAdType.FOOTER_MOBILE_LEADERBOARD_2]: (
        <div className="hb-ad-static hb-ad-a320x100 d-flex-center">
            <div className="hb-ad-inner">
                <div className="hbagency_cls_static" id="hbagency_space_81990"></div>
            </div>
        </div>
    ),
    [HbAdType.AF_300x250_2]: (
        <div className="hb-ad-inarticle hb-container d-flex-center">
            <div className="hb-ad-inner">
                <div className="hbagency_cls" id="hbagency_space_37288"></div>
            </div>
        </div>
    ),
    [HbAdType.AF_300x100_MOBILE]: (
        <div className="hb-ad-static hb-ad-a320x100 d-flex-center">
            <div className="hb-ad-inner">
                <div className="hbagency_cls_static" id="hbagency_space_176437"></div>
            </div>
        </div>
    ),
    [HbAdType.STICKY_BOTTOM]: (
        <div id="HB_Footer_Close_hbagency_space_176925">
            <div id="HB_CLOSE_hbagency_space_176925"></div>
            <div id="HB_OUTER_hbagency_space_176925">
                <div id="hbagency_space_176925"></div>
            </div>
        </div>
    ),
    [HbAdType.MAGIC_RIGHT]: (
        <div id="HB_Footer_Close_hbagency_space_177436">
            <div id="HB_CLOSE_hbagency_space_177436"></div>
            <div id="HB_OUTER_hbagency_space_177436">
                <div id="hbagency_space_177436"></div>
            </div>
        </div>
    ),
    [HbAdType.BILLBOARD]: (
        <div className="hb-ad-static hb-ad-billboard d-flex-center">
            <div className="hb-ad-inner">
                <div className="hbagency_cls_static" id="hbagency_space_177071"></div>
            </div>
        </div>
    ),
    [HbAdType.AF_INTERSCROLLER]: (
        <div class="hb-ad-inarticle d-flex-center">
            <div class="hb-ad-inner">
                <div class="hbagency_cls" id="hbagency_space_177386"></div>
            </div>
        </div>
    ),
};

const HbAdComponent = ({ type }) => {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true);
    }, []);

    useEffect(() => {
        if (!isClient) {
            return;
        }
    }, [type, isClient]);

    if (!isClient) {
        return null;
    }

    const dataFromCookies = getFromLocalStorage(SITE_USER_COOKIE);
    const data = JSON.parse(dataFromCookies);

    if (data && data.subscriptionType !== SUBSCRIPTION_TYPE.FREE) {
        return null;
    }

    return adComponents[type] || null;
};

export default HbAdComponent;
